import React, { useEffect, useRef, useState } from 'react';

const ImageUploadAndPreview = () => {
    const [image, setImage] = useState(null);
    const [boxes, setBoxes] = useState([]);
    const canvasRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState(null);
    const handleImageUpload = async (event) => {
        setIsLoading(true)
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);

            // Upload the image to FastAPI
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch('https://api.ticketing.sd/ocr/', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            setResult(result)
            const predictions = result.predictions;

            if (predictions && predictions.length > 0) {
                const textLines = predictions[0].text_lines;
                setBoxes(textLines.map(line => ({
                    x: line.bbox[0],
                    y: line.bbox[1],
                    width: line.bbox[2] - line.bbox[0],
                    height: line.bbox[3] - line.bbox[1],
                    text: line.text,
                    confidence: line.confidence,
                })));
            }

            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (canvasRef.current && image) {
            handleDrawBoxes(canvasRef.current, image);
        }
    }, [canvasRef.current, image, boxes]);


    const handleDrawBoxes = (canvas, image) => {
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = image;
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            boxes.forEach((box) => {
                ctx.beginPath();
                ctx.rect(box.x, box.y, box.width, box.height);
                ctx.stroke();
                ctx.font = "16px Arial";
                ctx.fillStyle = "red";
                ctx.fillText(`${box.text} (${box.confidence.toFixed(2)})`, box.x, box.y - 5);
            });
        };
        console.log(canvasRef);
    };
    return (
        <div style={{ textAlign: 'center' }}>
            <h1 >Target OCR Demo</h1>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
            <br />
            <br />
            {image && (
                <>
                    {boxes.length > 0 && <canvas ref={canvasRef} className="border border-gray-400 w-5/6 mx-auto" />}
                    {
                        isLoading &&
                        <div className='w-5/6 mx-auto'>
                            {
                                canvasRef.current !== null ? <canvas ref={canvasRef} style={{ border: '1px solid black' }}></canvas> : <div className="relative">
                                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                                        <svg class="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg></div>
                                    <img src={image} alt="image" className='blur-sm z-0 bg-white/30' />
                                </div>
                            }
                        </div>
                    }
                </>
            )}
            {
                result && (
                    <div className="flex flex-col">
                        
                        {result.predictions[0].text_lines.map((line, index) => (
                            <div className="">{line.text}</div>
                        ))}
                    </div>
                )
            }
        </div>
    );
};

export default ImageUploadAndPreview;